<template>
  <div class="app-confirm">
    <v-dialog v-model="show" max-width="400" absolute>
      <v-card>
        <v-card-title v-if="title">{{ title }}</v-card-title>
        <v-card-text v-if="message" class="pt-4 body-1">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-btn class="gray--text lighten-2" text @click="confirmCancel">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" text @click="confirmOk">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
//  import _ from 'lodash'

export default {
  name: 'AppConfirm',

  data() {
    return {
      show: null,
      title: null,
      message: null
    }
  },

  created() {
    let me = this
    me.$bus.$off('show-confirm')
    me.$bus.$on('show-confirm', payload => {
      me.showConfirm(payload)
    })
  },

  methods: {
    confirmCancel() {
      let me = this
      me.$bus.$emit('confirm.cancel')
      me.reset()
    },

    confirmOk() {
      let me = this
      me.$bus.$emit('confirm.ok')
      me.reset()
    },

    reset() {
      let me = this
      me.show = false
      me.title = null
      me.message = null
    },

    showConfirm({ message, title = null }) {
      let me = this
      me.$debug('showConfirm', message, title)
      me.title = title
      me.message = message
      me.show = true
    }
  }
}
</script>
