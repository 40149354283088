import _ from 'lodash'
import Vue from 'vue'
import * as _debug from 'debug'
import ApiClient from '@/lib/ApiClient'
import { objectDiff, sureClone } from '@/lib/DataUtils'

const debug = _debug('vuex:module:admin:users')

import ModuleFactory from '@/store/common/ModuleFactory'

const DEFAULT_EDITOR = {
  id: null,
  firstname: null,
  lastname: null,
  email: null,
  password: null,
  organisation_id: null
}

export default ModuleFactory({
  state: {
    users: null,
    editor: sureClone(DEFAULT_EDITOR)
  },
  getters: {
    editorChanges(state) {
      const userId = _.get(state, 'editor.id')
      if (!userId) {
        return null
      }
      const original = _.get(state.users, userId)
      if (!original) {
        return null
      }
      return objectDiff(original, state.editor)
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges
      return diff && !_.isEmpty(diff)
    },
    users({ users }) {
      return _.values(users)
    },
    usersByOrganisation: state => id => {
      //TODO: state -> users ???
      let users = state.users
      return _.values(users).filter(u => u.organisation === id)
    }
  },
  mutations: {
    newUserToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR)
    },
    addUser(state, user) {
      Vue.set(state.users, user.id, user)
    }
  },
  actions: {
    async load({ commit }) {
      debug('load')
      let users = await ApiClient.get(`/users`)
      debug('load', users)
      commit('set', ['users', _.keyBy(users, 'id')])
    },
    async createUser({ state, commit }) {
      let user = sureClone(state.editor)
      _.unset(user, 'id')
      debug('createUser', user)
      let response = await ApiClient.post('/users', user)
      debug('createUser - response', response)
      commit('addUser', response)
      return response
    },

    async loadUserToEditor({ state, commit }, userId) {
      debug('loadUserToEditor', userId)
      const user = _.get(state.users, userId)
      if (!user) {
        throw new Error('User not found')
      }
      commit('set', ['editor', sureClone(user)])
    },

    async updateUserInEditor({ state, getters, commit }) {
      let userId = state.editor.id
      if (!getters.editorHasChanges) {
        throw new Error('No changes to save')
      }
      let changes = getters.editorChanges
      debug('updateUserInEditor', userId, changes)
      let result = await ApiClient.patch(`/users/${userId}`, changes)
      debug('updateUserInEditor - result', result)
      commit('mapSet', [`users`, userId, result])
      commit('set', ['editor', result])
    }
  }
})
