import validator from 'validator'
export default {
  computed: {
    $rules: () => ({
      required: [v => (!!v && v.length >= 0) || 'This field is required'],
      selection: [v => !!v || 'This selection is required'],
      email: [
        v => (!!v && validator.isEmail(v)) || 'This email address is not valid'
      ],
      password: [v => (!!v && v.length >= 8) || 'This password is too short'],
      number: [v => !!v || 'This field is required']
    })
  }
}
