import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { Timeline } from 'vue2vis'

import 'vue2vis/dist/vue2vis.css'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import './scss/overrides.sass'

import './mixins/GlobalMixin'

Vue.config.productionTip = false

Vue.component('timeline', Timeline)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

console.log('AdPlanner build version', process.env.VUE_APP_BUILD_VERSION)
