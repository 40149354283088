import _ from 'lodash'
import * as _debug from 'debug'
import ApiClient from '@/lib/ApiClient'
const debug = _debug('vuex:module:admin:organisations')
import ModuleFactory from '@/store/common/ModuleFactory'
import { objectDiff, sureClone } from '@/lib/DataUtils'

const DEFAULT_EDITOR = {
  id: null,
  name: null,
  logo_url: '',
  brand_color: '',
  channels: []
}
export default ModuleFactory({
  state: {
    organisations: null,
    editor: sureClone(DEFAULT_EDITOR)
  },
  getters: {
    editorChanges(state) {
      const orgId = _.get(state, 'editor.id')
      if (!orgId) {
        return null
      }
      const original = _.get(state.organisations, orgId)
      if (!original) {
        return null
      }
      return objectDiff(original, state.editor)
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges
      return diff && !_.isEmpty(diff)
    },
    stateOrganisations(state) {
      return _.flatMap(state.organisations)
    },
    organisations({ organisations }) {
      return _.values(organisations)
    },
    channels(org) {
      debug('organisations are getting this org: ', org)
      let channels = _.orderBy(_.flatMap(org.channelAccess, 'channel'), 'name')
      debug('organisations are returning these channels: ', channels)
      return channels
    }
  },

  actions: {
    async load({ commit }) {
      debug('load organisations')
      let organisations = await ApiClient.get(`/organisations`)
      debug('load', organisations)
      commit('set', ['organisations', _.keyBy(organisations, 'id')])
    },

    async createOrganisation({ state, commit }) {
      let org = sureClone(state.editor)
      _.unset(org, 'id')
      debug('createOrganisation', org)
      let response = await ApiClient.post('/organisations', org)
      debug('createOrganisation - response', response)
      commit('mapSet', ['organisations', response.id, response])
      return response
    },

    async loadOrgToEditor({ state, commit }, orgId) {
      debug('loadOrgToEditor', orgId)
      const org = _.get(state.organisations, orgId)
      if (!org) {
        throw new Error('Org not found')
      }
      commit('set', ['editor', sureClone(org)])
    },

    async updateOrgInEditor({ state, getters, commit }) {
      const orgId = state.editor.id
      if (!getters.editorHasChanges) {
        throw new Error('No changes to save')
      }
      let changes = getters.editorChanges
      debug('updateUserInEditor', orgId, changes)
      let result = await ApiClient.patch(`/organisations/${orgId}`, changes)

      debug('updateUserInEditor - result', result)
      commit('mapSet', [`organisations`, orgId, result])
      commit('set', ['editor', result])
    }
  }
})
