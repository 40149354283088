import _ from 'lodash'
import Vue from 'vue'
import * as _debug from 'debug'
import ApiClient from '@/lib/ApiClient'
import { objectDiff, sureClone, mapDiff } from '../../lib/DataUtils'
import Promise from 'bluebird'
import moment from 'moment'

const debug = _debug('vuex:module:campaigns')

import ModuleFactory from '../common/ModuleFactory'

const DEFAULT_EDITOR = {
  id: null,
  organisation: null,
  name: null,
  type: 'switch-in',
  channels: [],
  settings: {
    start_time: null,
    end_time: null
  },
  uses_legal_template: false,
  legal_template: null,
  launch_count: null,
  is_interactive: false,
  editable_impressions: false,
  start_date: null,
  included_tv_programs: [],
  excluded_tv_programs: [],
  suite_state: null
  /*
     SUITE_STATES = Choices(
        (0, 'edit', _('Edit')),
        (2, 'approved', _('Approved')),
        (3, 'live', _('Live')),
        (4, 'inactive', _('Inactive')),
        (5, 'archived', _('Archived')),
    )
  */
}

export default ModuleFactory({
  state: {
    campaigns: null,
    editor: sureClone(DEFAULT_EDITOR),
    selectedTemplateId: null,
    assets: {},
    schedule: {},
    scheduleEditor: {},
    programs: []
  },

  getters: {
    editorChanges(state) {
      const campaignId = _.get(state, 'editor.id')
      if (!campaignId) {
        return null
      }
      const original = _.get(state.campaigns, campaignId)
      if (!original) {
        return null
      }
      return objectDiff(original, state.editor)
    },
    scheduleChanges(state) {
      return mapDiff(state.schedule, state.scheduleEditor)
    },
    hasScheduleChanges(state, getters) {
      return !_.isEmpty(getters.scheduleChanges)
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges
      return (diff && !_.isEmpty(diff)) || getters.hasScheduleChanges
    },
    editorAssetsByType(state) {
      if (_.isEmpty(state.assets)) {
        return {}
      }
      return _(state.assets)
        .values()
        .orderBy(['created_at', 'desc'])
        .groupBy('type')
        .mapValues(assets => {
          let first = _.first(assets)
          first._uri = first.path
          return first
        })
        .value()
    },
    campaignHasBeenPublished(state) {
      let campaignState = _.get(state, 'editor.state')

      if (campaignState) {
        if (
          campaignState === 'published' ||
          campaignState === 'started' ||
          campaignState === 'ended' ||
          campaignState === 'stopped'
        ) {
          return true
        }
      } else {
        return false
      }
    },
    suiteStateIsApproved(state) {
      let suiteState = _.get(state, 'editor.suite_state')
      if (suiteState === 2) {
        console.log('Suite state is Approved')
        return true
      } else {
        return false
      }
    },
    // TODO: Implement this logic to determine if campaign is ready for publish or not
    readyForPublish(state, getters) {
      if (!getters.editorHasChanges) {
        let editor = state.editor
        let assets = _.values(state.assets)
        var l_banner_bottom_left = assets.find(function(element) {
          return element.type == 'l-banner-bottom-left'
        })
        var l_banner_bottom_right = assets.find(function(element) {
          return element.type == 'l-banner-bottom-right'
        })

        let interactiveCheck = true
        if (state.editor.is_interactive) {
          var app_creative_original = assets.find(function(element) {
            return element.type == 'app-creative-original'
          })
          var app_legal_original = assets.find(function(element) {
            return element.type == 'app-legal-original'
          })
          let legal_template = editor.legal_template
            ? _.isEmpty(editor.legal_template.trim())
            : true
          if (!app_creative_original || !app_legal_original || legal_template) {
            interactiveCheck = false
          }
        }

        if (
          interactiveCheck &&
          editor.name &&
          editor.client &&
          editor.channels &&
          editor.settings && //Right now additinoal checks are not needed, adding them later if needed.
          editor.start_date &&
          state.editor.settings.campaign_length_days &&
          _.values(state.scheduleEditor).length > 0 &&
          l_banner_bottom_left &&
          l_banner_bottom_right
        ) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    campaigns({ campaigns }) {
      let vls = _.values(campaigns)
      return vls
    },

    published({ campaigns }) {
      return _.values(campaigns).filter(c => c.state === 'published')
    },

    ongoing({ campaigns }) {
      return _.values(campaigns).filter(
        c =>
          c.state === 'published' ||
          c.state === 'started' ||
          c.state === 'stopped'
      )
    },
    draft({ campaigns }) {
      return _.values(campaigns).filter(c => c.state === 'draft')
    },
    past({ campaigns }) {
      return _.values(campaigns).filter(c => c.state === 'ended')
    },
    template({ campaigns }) {
      return _.values(campaigns).filter(c => c.state === 'template')
    },
    schedule({ scheduleEditor }) {
      return _.orderBy(_.values(scheduleEditor), ['start_time'], ['asc'])
    },
    programs({ programs }) {
      return _.orderBy(
        _.uniqBy(_.values(programs), e => e.title['#text']),
        ['title["#text"]']
      )
    },
    includedTVPrograms({ editor }) {
      return _.orderBy(
        _.uniqBy(_.values(editor.included_tv_programs), e => e.title['#text']),
        ['title["#text"]']
      )
    },
    excludedTVPrograms({ editor }) {
      return _.orderBy(
        _.uniqBy(_.values(editor.excluded_tv_programs), e => e.title['#text']),
        ['title["#text"]']
      )
    }
  },

  mutations: {
    newCampaignToEditor(state, organisationId) {
      state.editor = sureClone(DEFAULT_EDITOR)
      state.editor.organisation = organisationId
      state.selectedTemplateId = null
    },
    addCampaign(state, campaign) {
      Vue.set(state.campaigns, campaign.id, campaign)
    },
    addScheduleRow(state, schedule) {
      Vue.set(state.scheduleEditor, schedule.id, schedule)
    },
    addPrograms(state, programs) {
      state.programs = state.programs.concat(programs)
    },
    clearPrograms(state) {
      state.programs = []
    }
  },

  actions: {
    async load({ commit }, organisationId) {
      debug('load', organisationId)
      let campaigns = await ApiClient.get(`/campaigns`, {
        params: {
          organisation_id: organisationId
        }
      })
      debug('load', campaigns)
      commit('set', ['campaigns', _.keyBy(campaigns, 'id')])
    },
    async duplicateCampaign({ commit }, campaignId) {
      debug('duplicateCampaign', campaignId)
      let duplicate = await ApiClient.post(`/campaigns/${campaignId}/duplicate`)
      debug('duplicateCampaign - response', duplicate)
      commit('addCampaign', duplicate)
      return duplicate
    },
    async deleteCampaign({ commit }, campaignId) {
      debug('deleteCampaign', campaignId)
      let response = await ApiClient.delete(`/campaigns/${campaignId}`)
      debug('deleteCampaign - response', response)
      commit('mapUnset', ['campaigns', campaignId])
    },
    async createCampaign({ state, commit }) {
      let campaign = sureClone(state.editor)
      _.unset(campaign, 'id')
      debug('createCampaign', campaign)
      let duplicate = await ApiClient.post(
        `/campaigns/${state.selectedTemplateId}/duplicate`
      )
      let response = await ApiClient.patch(`/campaigns/${duplicate.id}`, {
        state: 'draft',
        name: state.editor.name,
        client: state.editor.client
      })
      debug('createCampaign - response', response)
      commit('addCampaign', response)
      return response
    },
    async createScheduleRow({ state, commit }, row) {
      let index = _.size(state.scheduleEditor)
      let schedule = {
        id: 'added-schedule-' + index,
        start_time: row.start_time.utc().format(),
        end_time: row.end_time.utc().format(),
        campaign: state.editor.id
      }
      commit('addScheduleRow', schedule)
    },
    async updateIncludedTVProgramsToEditor({ commit }, programs) {
      debug('setIncludedTVPrograms', programs)
      commit('set', ['editor.included_tv_programs', programs])
    },
    async updateExcludedTVProgramsToEditor({ commit }, programs) {
      debug('setExcludedTVPrograms', programs)
      commit('set', ['editor.excluded_tv_programs', programs])
    },
    async removeTVProgramsFromEditor({ state, commit }, programIds) {
      debug('removeTVProgramsFromEditor', programIds)
      var newIncluded = _.filter(
        state.editor.included_tv_programs,
        p => !_.includes(programIds, p['@id'])
      )
      commit('set', ['editor.included_tv_programs', newIncluded])
      var newExcluded = _.filter(
        state.editor.excluded_tv_programs,
        p => !_.includes(programIds, p['@id'])
      )
      commit('set', ['editor.excluded_tv_programs', newExcluded])
    },
    async loadCampaignToEditor({ state, commit, dispatch }, campaignId) {
      debug('loadCampaignToEditor', campaignId)
      const campaign = _.get(state.campaigns, campaignId)
      if (!campaign) {
        throw new Error('Campaign not found')
      }
      commit('set', ['editor', sureClone(campaign)])

      await dispatch('loadAssets', campaignId)
      await dispatch('loadSchedule', campaignId)
    },
    async loadAssets({ state, commit }, campaignId = null) {
      if (!campaignId) {
        campaignId = state.editor.id
      }
      let assets = await ApiClient.get(`/campaign_assets`, {
        params: {
          campaign: campaignId
        }
      })
      // TODO: Remove this debug when api supports filtering
      assets = _.filter(assets, ['campaign', campaignId])
      debug('load assets', campaignId, assets)
      commit('set', ['assets', _.keyBy(assets, 'id')])
    },
    async loadSchedule({ state, commit }, campaignId = null) {
      if (!campaignId) {
        campaignId = state.editor.id
      }
      let schedule = await ApiClient.get(`/campaign_schedules`, {
        params: {
          campaign: campaignId
        }
      })
      debug('load schedule', campaignId, schedule)
      commit('set', ['schedule', _.keyBy(schedule, 'id')])
      commit('set', ['scheduleEditor', sureClone(state.schedule)])
    },
    async loadTVPrograms({ state, rootState, commit }) {
      debug('loadTVPrograms', rootState.organisation.channelAccess)
      let epgChannelIds = _.map(
        state.editor.channels,
        ch =>
          _.find(
            rootState.organisation.channelAccess,
            ca => ca.channel.id == ch
          ).channel.epg_channel_id
      )
      debug('epg ids are ', epgChannelIds)
      commit('clearPrograms')
      await Promise.map(
        epgChannelIds,
        async epgChannelId => {
          debug('load epg for ', epgChannelId)
          let response = await ApiClient.get(`/epg/${epgChannelId}`)
          try {
            let programs = _.uniqBy(response.tv.programme, 'title')
            debug('programs', programs)
            commit('addPrograms', programs)
          } catch {
            //Catch and continue. Other channels may be ok.
            debug('could not parse tv programs from response', response)
          }
        },
        {
          concurrency: 5
        }
      )
    },

    async publishCampaign({ state, getters, dispatch }) {
      if (getters.editorHasChanges) {
        this.$toast({
          type: 'warning',
          message: `You have unsaved changes for this campaign. Save changes and try again.`
        })
      } else {
        await ApiClient.post(`/campaigns/${state.editor.id}/publish`)

        // With this we make sure the local store also gets updated with the right state.
        let response = await ApiClient.get(`/campaigns/${state.editor.id}`)
        _.set(state, 'editor.state', response.state)
        await dispatch('updateCampaignInEditor')
      }
    },

    async goLive({ state, getters, dispatch }) {
      if (getters.editorHasChanges) {
        this.$toast({
          type: 'warning',
          message: `You have unsaved changes for this campaign. Save changes and try again.`
        })
      } else {
        await ApiClient.post(`/campaigns/${state.editor.id}/go_live`)

        // With this we make sure the local store also gets updated with the right state.
        let response = await ApiClient.get(`/campaigns/${state.editor.id}`)
        console.log(response)
        _.set(state, 'editor.state', response.state)
        _.set(state, 'editor.suite_state', response.suite_state)
        await dispatch('updateCampaignInEditor')
      }
    },

    async updateCampaignInEditor({ state, getters, commit, dispatch }) {
      let campaignId = state.editor.id
      if (!getters.editorHasChanges) {
        throw new Error('No changes to save')
      }
      let changes = getters.editorChanges
      debug('updateCampaignInEditor', campaignId, changes)
      let result = await ApiClient.patch(`/campaigns/${campaignId}`, changes)
      debug('updateCampaignInEditor - result', result)
      commit('mapSet', [`campaigns`, campaignId, result])
      commit('set', ['editor', sureClone(result)])

      await dispatch('updateScheduleInEditor')
    },
    async updateScheduleInEditor({ state, getters, dispatch }) {
      let campaignId = state.editor.id
      if (getters.hasScheduleChanges) {
        debug('updateScheduleInEditor', campaignId)

        let scheduleReplaceObject = {
          schedules: _.values(state.scheduleEditor)
        }

        let result = await ApiClient.post(
          `/campaigns/${campaignId}/replace_schedules`,
          scheduleReplaceObject
        )
        debug('updateScheduleInEditor - result', result)

        await dispatch('loadSchedule', campaignId)
      }
    },
    async uploadBlob({ state, commit }, { filename, type, blob }) {
      const campaignId = state.editor.id
      const data = new FormData()
      data.set('campaign', campaignId)
      data.set('type', type)
      data.append('path', blob, filename)

      let asset = await ApiClient.postMultipart('/campaign_assets', data)
      debug('uploadBlob - asset', asset)
      commit('mapSet', ['assets', asset.id, asset])
    },
    deleteItemInSchedule({ commit }, scheduleId) {
      commit('mapUnset', ['scheduleEditor', scheduleId])
    },
    removeDailySchedule({ state, commit }) {
      state.scheduleEditor = {}
      commit('mapUnset', ['editor.settings', 'daily_schedule'])
      commit('set', ['editor.excluded_tv_programs', []])
    },
    createDailySchedule({ state, commit }) {
      if (_.isEmpty(state.editor.settings.daily_schedule)) {
        debug('No daily schedule times set, skip creating schedule')
        return
      }
      var campaignLength =
        moment(state.editor.end_date).diff(
          moment(state.editor.start_date),
          'days'
        ) + 1

      if (campaignLength < 1) {
        debug('Illegal campaign dates, skip createing schedule')
        return
      }

      state.scheduleEditor = {}
      let firstStartTime = new Date(
        `${state.editor.start_date} ${state.editor.settings.daily_schedule.start_time}`
      )
      let firstEndTime = new Date(
        `${state.editor.start_date} ${state.editor.settings.daily_schedule.end_time}`
      )
      _.range(0, campaignLength).forEach(daysOffset => {
        let start = moment(firstStartTime).add(daysOffset, 'd')
        let end = moment(firstEndTime).add(daysOffset, 'd')

        let schedule = {
          id: 'new-schedule-' + daysOffset,
          start_time: start,
          end_time: end,
          campaign: state.editor.id
        }
        commit('mapSet', ['scheduleEditor', schedule.id, schedule])
      })
    },
    async pauseCampaign({ state }, campaignId) {
      console.log('campaing ID : ' + campaignId)
      await ApiClient.post(`/campaigns/${campaignId}/stop`)
      let response = await ApiClient.get(`/campaigns/${campaignId}`)
      _.set(state, `campaigns.${campaignId}.state`, response.state)
      _.set(state, `campaigns.${campaignId}.suite_state`, response.suite_state)
    },
    async startCampaign({ state }, campaignId) {
      await ApiClient.post(`/campaigns/${campaignId}/restart`)
      let response = await ApiClient.get(`/campaigns/${campaignId}`)
      _.set(state, `campaigns.${campaignId}.state`, response.state)
      _.set(state, `campaigns.${campaignId}.suite_state`, response.suite_state)
    },

    async updateCampaings({ commit }, campaign) {
      debug('updateCampaings', campaign)
      commit('addCampaign', campaign)
    }
  }
})
