import _ from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'

import * as _debug from 'debug'
const debug = _debug('vuex:store')

Vue.use(Vuex)

import user from './modules/user'
import organisation from './modules/organisation'
import campaigns from './modules/campaigns'
import users from './modules/admin/users'
import templates from './modules/admin/templates'
import organisations from './modules/admin/organisations'
import channels from './modules/admin/channels'

export default new Vuex.Store({
  state: {
    appInited: false
  },
  getters: {
    appReady(state) {
      return state.appInited
    }
  },
  mutations: {
    set(state, [key, value]) {
      if (key.match(/\.|\[/)) {
        _.set(state, key, value)
      } else {
        Vue.set(state, key, value)
      }
    }
  },
  actions: {
    async initAfterLogin({ getters, dispatch, commit }) {
      let user = getters['user/user']
      debug('initAfterLogin', user)
      const isSuperUser = !user.organisation
      if (isSuperUser) {
        debug('I AM SUPERUSER!!!')
        await dispatch('organisations/load')
        await dispatch('channels/load')
        await dispatch('users/load')
        await dispatch('templates/load')
      } else {
        await dispatch('organisation/load', user.organisation)
        await dispatch('campaigns/load', user.organisation)
      }
      commit('set', ['appInited', true])
    },

    resetDefaultState({ commit }) {
      debug('resetDefaultState')
      commit('user/reset_default_state')
    }
  },
  modules: {
    user,
    organisation,
    campaigns,
    templates,
    organisations,
    users,
    channels
  }
})
