import _ from 'lodash'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    components: {
      default: () =>
        import(
          /* webpackChunkName: "dashboard" */ '../views/user/dashboard/Dashboard'
        ),
      admin: () =>
        import(
          /* webpackChunkName: "dashboard" */ '../views/admin/dashboard/Dashboard'
        )
    },
    children: [
      {
        path: 'create-organisation',
        name: 'organisation-creator',
        component: () =>
          import(
            /* webpackChunkName: "organisation" */ '../views/admin/organisation/OrganisationCreator'
          ),
        meta: {
          title: 'Organisation'
        },
        props: true
      },
      {
        path: 'create-user',
        name: 'user-creator',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '../views/admin/user/UserCreator'
          ),
        meta: {
          title: 'Create user'
        },
        props: true
      },
      {
        path: '/user/:userId',
        name: 'user-editor',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '../views/admin/user/UserEditor'
          ),
        meta: {
          title: 'Edit user'
        },
        props: true
      },
      {
        path: 'users/:organisationId',
        name: 'users',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "user" */ '../views/admin/dashboard/components/UserListWidget'
            ),
          button: () =>
            import(
              /* webpackChunkName: "newUserButton" */ '../views/admin/dashboard/components/NewUserButton'
            )
        },
        meta: {
          title: 'Users'
        },
        props: true
      },
      {
        path: 'settings/:organisationId',
        name: 'settings',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '../views/admin/organisation/OrganisationEditor'
          ),
        meta: {
          title: 'Settings'
        },
        props: true
      },
      {
        path: 'create-template',
        name: 'template-creator',
        component: () =>
          import(
            /* webpackChunkName: "template" */ '../views/admin/template/TemplateCreator'
          ),
        meta: {
          title: 'Create template'
        },
        props: true
      },
      {
        path: '/template/:templateId',
        name: 'template-editor',
        component: () =>
          import(
            /* webpackChunkName: "template" */ '../views/admin/template/TemplateEditor'
          ),
        meta: {
          title: 'Edit template'
        },
        props: true
      },
      {
        path: 'templates/:organisationId',
        name: 'templates',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "template" */ '../views/admin/dashboard/components/TemplateListWidget'
            ),
          button: () =>
            import(
              /* webpackChunkName: "newTemplateButton" */ '../views/admin/dashboard/components/NewTemplateButton'
            )
        },
        meta: {
          title: 'Templates'
        },
        props: true
      },
      {
        path: '',
        name: 'home',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "user" */ '../views/admin/dashboard/components/UserListWidget'
            ),
          button: () =>
            import(
              /* webpackChunkName: "newUserButton" */ '../views/admin/dashboard/components/NewUserButton'
            )
        },
        meta: {
          title: 'Users'
        },
        props: true
      }
    ],
    meta: {
      title: 'Admin'
    }
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: () =>
      import(
        /* webpackChunkName: "timeline" */ '../views/user/dashboard/Timeline'
      ),
    meta: {
      title: 'Timeline'
    }
  },
  {
    path: '/campaign/:campaignId',
    name: 'campaign-editor',
    component: () =>
      import(
        /* webpackChunkName: "campaign" */ '../views/user/campaign/CampaignEditor'
      ),
    meta: {
      title: 'Campaign'
    },
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let title = _.get(to, 'meta.title', '')
  if (title) {
    title += ' - '
  }
  title += 'Digita Ad Planner'
  document.title = title
  next()
})

export default router
