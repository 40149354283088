export default {
  computed: {
    $isLogged() {
      return this.$store.getters['user/isLogged']
    },
    $isSuperuser() {
      return this.$store.getters['user/isSuperuser']
    },
    $isOrgAdmin() {
      return this.$store.getters['user/isOrgAdmin']
    }
  }
}
