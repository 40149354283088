import _ from 'lodash'
import * as _debug from 'debug'
import * as jwt_decode from 'jwt-decode'
import { ApiClient } from '@/lib/ApiClient'

const debug = _debug('vuex:module:user')

const AUTH_TOKEN_KEY = process.env.VUE_APP_AUTH_TOKEN_KEY

import ModuleFactory from '../common/ModuleFactory'

export default ModuleFactory({
  state: {
    user: {
      id: null,
      firstname: null,
      lastname: null,
      email: null,
      role: null,
      organisation: null,
      is_org_admin: null
    }
  },
  getters: {
    isLogged(state) {
      return !!state.user.id
    },

    isSuperuser(state) {
      return !state.user.organisation
    },

    isOrgAdmin(state) {
      return state.user.is_org_admin
    },

    user(state) {
      return state.user
    }
  },
  actions: {
    async autologin({ dispatch }) {
      const token = localStorage.getItem(AUTH_TOKEN_KEY)
      if (!token) {
        debug('autologin', false)
        return false
      }
      debug('autologin', true)

      try {
        await ApiClient.post('/auth/verify', {
          token
        })
      } catch (err) {
        localStorage.removeItem(AUTH_TOKEN_KEY)
        debug('autologin - invalid token')
        return false
      }

      await dispatch('afterLogin')
      return true
    },

    async login({ dispatch }, { email, password }) {
      debug('login', email)

      try {
        let response = await ApiClient.post('/auth/login', {
          email,
          password
        })
        const token = _.get(response, 'token')
        if (!token) {
          throw new Error('Authentication failed for unknown reason.')
        }
        localStorage.setItem(AUTH_TOKEN_KEY, token)
      } catch (err) {
        debug('login error', err)
        throw new Error('Authentication failed, invalid username or password')
      }

      await dispatch('afterLogin')
    },

    async afterLogin({ dispatch }) {
      await dispatch('load')
      await dispatch('initAfterLogin', null, { root: true })
    },

    async logout({ dispatch }) {
      debug('logout')
      localStorage.removeItem(AUTH_TOKEN_KEY)
      dispatch('resetDefaultState', null, { root: true })
    },

    async load({ commit }) {
      const token = localStorage.getItem(AUTH_TOKEN_KEY)
      if (!token) {
        throw new Error('Unable to load user because of missing token')
      }
      const decoded = jwt_decode(token)
      debug('decoded', decoded)
      const userId = _.get(decoded, 'user_id')
      debug('load', userId)
      let user = await ApiClient.get(`/users/${userId}`)
      debug('load - response', user)

      commit('set', ['user', user])
    }
  }
})
