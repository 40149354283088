/* eslint-disable no-console */

import _ from 'lodash'
import { sprintf } from 'sprintf-js'
import Vue from 'vue'

const Debug = require('debug')

const globalEventBus = new Vue()

export default Vue.mixin({
  computed: {
    $bus() {
      return globalEventBus
    },

    $currentUser() {
      return this.$store.state.user
    }
  },

  methods: {
    /**
     * Programmatically navigate to route. If object then Vue Router object
     * https://router.vuejs.org/en/essentials/navigation.html
     * @param location string|object
     */
    async $redirect(location) {
      await this.$router.push(location)
    },

    $cleanObject(obj) {
      if (!obj) {
        return null
      }
      return JSON.parse(JSON.stringify(obj))
    },

    // futureproofing multilanguage support
    $t(text) {
      let partials = Array.from(arguments).slice(1)
      return sprintf(text, ...partials)
    },

    $toast({ type = 'error', message = null, details = null, close = true }) {
      this.$bus.$emit('show-toast', {
        type,
        message,
        details,
        close
      })
    },

    $confirm(message = null, title = null) {
      let me = this
      return new Promise((resolve, reject) => {
        me.$bus.$on('confirm.cancel', () => {
          reject(new Error('cancel'))
          me.$bus.$off(['confirm.cancel', 'confirm.ok'])
        })
        me.$bus.$on('confirm.ok', () => {
          resolve()
          me.$bus.$off(['confirm.cancel', 'confirm.ok'])
        })
        me.$bus.$emit('show-confirm', {
          title: title,
          message: message
        })
      })
    },

    $alert(message, timeout = 6000) {
      this.$toast({
        type: 'warning',
        message: message,
        timeout: timeout
      })
    },

    $error(err) {
      let me = this
      if (_.isString(err)) {
        me.$toast({
          type: 'error',
          message: err
        })
      } else if (err && err.response && err.response.status) {
        me.$toast({
          type: 'error',
          message: `Request failed with status code ${err.response.status}`,
          details: `Server returned: ${JSON.stringify(err.response.data)}`
        })
      } else if (err && err.message) {
        me.$toast({
          type: 'error',
          message: err.message
        })
        console.error(err)
      }
    },

    $debug() {
      let me = this
      let identifier = 'unknown'
      if (me._name) {
        identifier = me._name
      } else if (me.$options.__file) {
        identifier = me.$options.__file
      }
      Debug(process.env.VUE_APP_PREFIX + ':' + identifier)(...arguments)
    },

    $timeout(ms) {
      return new Promise(res => setTimeout(res, ms))
    },

    $randomString(length = 6) {
      if (length < 1 || length > 12) {
        throw new Error(
          '$randomString only supports string lengths from 1 to 12'
        )
      }
      return Math.random()
        .toString(36)
        .slice(13 - length)
    },

    $capitalize(str) {
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : null
    }
  }
})
