<template>
  <v-app>
    <AppBar v-if="$isLogged"></AppBar>
    <!--
    TODO: Tähän kohtaan AdminNav komponentti joka on
    v-navigation-drawer komponenttia hyödyntävä navigaatiopaneeli
    -->
    <v-content>
      <v-container
        v-if="init"
        class="init"
        fluid
        fill-height
        d-flex
        justify-center
      >
        <Loader></Loader>
      </v-container>
      <router-view
        v-else-if="$isLogged && $isSuperuser"
        name="admin"
      ></router-view>
      <router-view v-else-if="$isLogged"></router-view>
      <Login v-else></Login>
    </v-content>
    <v-footer v-if="$isLogged" min-height="80px" class="caption">
      <v-row>
        <v-col>Digita Hybrid TV Ad Planner</v-col>
        <v-col class="text-right">UI version: {{ version }}</v-col>
      </v-row>
    </v-footer>
    <toast></toast>
    <confirm></confirm>
    <swUpdateNotifier></swUpdateNotifier>
  </v-app>
</template>
<style lang="sass" scoped>
@import '@/scss/variables.scss'
.init
  background-color: map-get($digita, 'grey')
</style>
<style lang="sass">
@import '~vuetify/src/styles/styles.sass'
.max-xl
  max-width: #{map-get($grid-breakpoints, 'xl')}
</style>
<script>
// import _ from 'lodash'

import AuthMixin from './mixins/AuthMixin'

import Loader from './components/common/Loader'
import Login from './components/common/Login'
import Confirm from './components/common/Confirm'
import Toast from './components/common/Toast'
import SwUpdateNotifier from './components/common/SwUpdateNotifier'

import AppBar from './components/common/AppBar'

export default {
  name: 'App',

  components: {
    AppBar,
    Loader,
    Login,
    Confirm,
    Toast,
    SwUpdateNotifier
  },

  mixins: [AuthMixin],

  data: () => ({
    init: true,
    version: process.env.VUE_APP_BUILD_VERSION
  }),

  async mounted() {
    let me = this

    try {
      await me.$store.dispatch('user/autologin')
    } catch (err) {
      me.$error(err)
    }

    me.init = false
  }
}
</script>
