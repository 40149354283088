<template>
  <v-app-bar app dark>
    <v-toolbar-title class="d-flex align-center">
      <img src="@/assets/digita-logo-dark.png" style="max-height: 34px" />
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-title class="text-center d-none d-md-flex">
      {{ $t('Hybrid TV Ad Planner') }}
      {{ $isSuperuser ? ' / ' + $t('Admin') : '' }}
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu offset-y>
      <template v-slot:activator="{ on }" :nudge-top="30">
        <v-btn dark outlined v-on="on">
          {{ userName }}
          <v-icon right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="logout">
          <v-list-item-title>{{ $t('Log out') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import _ from 'lodash'
import AuthMixin from '../../mixins/AuthMixin'

export default {
  mixins: [AuthMixin],
  computed: {
    userName() {
      let user = this.$store.getters['user/user']
      return _.startCase(user.firstname).slice(0, 1) + '. ' + user.lastname
    }
  },

  methods: {
    async logout() {
      const path = '/'
      if (this.$route.path !== path) this.$router.replace({ name: 'dashboard' })
      await this.$store.dispatch('user/logout')
    }
  }
}
</script>
