<template>
  <div class="app-toast">
    <v-snackbar
      v-if="show"
      v-model="show"
      :color="toast.type || 'info'"
      multi-line
      :bottom="false"
      :top="true"
      :timeout="timeout"
    >
      <div class="ml-4">
        <v-row>
          {{ toast.message }}
          <v-btn
            v-if="hasDetails && !showDetails"
            text
            x-small
            @click="showDetailsHandler"
          >
            Show details
          </v-btn>
        </v-row>

        <v-row v-if="hasDetails && showDetails">
          {{ toast.details }}
        </v-row>
      </div>

      <v-btn v-if="close" dark icon @click.native="show = null">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
// import _ from 'lodash'

export default {
  name: 'Toast',

  data() {
    return {
      show: null,
      showDetails: false,
      timeout: 6000,
      toast: {
        type: null,
        message: false,
        details: null
      },
      close: true
    }
  },
  computed: {
    hasDetails() {
      return this.toast.details != null && this.toast.details != ''
    }
  },
  created() {
    let me = this
    me.$bus.$off('show-toast')
    me.$bus.$on('show-toast', payload => {
      me.showToast(payload)
    })
  },

  methods: {
    showToast({ type, message, details, close = true }) {
      let me = this
      me.toast.type = type
      me.toast.message = message
      me.toast.details = details
      me.close = close
      me.show = true
      me.showDetails = false
    },
    showDetailsHandler() {
      let me = this
      me.showDetails = true
    }
  }
}
</script>
