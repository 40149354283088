import _ from 'lodash'
import Vue from 'vue'
import * as _debug from 'debug'
import ApiClient from '@/lib/ApiClient'
import { objectDiff, sureClone } from '@/lib/DataUtils'

const debug = _debug('vuex:module:admin:templates')

import ModuleFactory from '@/store/common/ModuleFactory'

const DEFAULT_EDITOR = {
  id: null,
  organisation: null,
  name: '',
  type: 'switch-in',
  channels: [],
  settings: {},
  legal_template: '',
  is_interactive: false,
  editable_impressions: false
}

export default ModuleFactory({
  state: {
    campaigns: null,
    editor: sureClone(DEFAULT_EDITOR)
  },

  getters: {
    editorChanges(state) {
      const campaignId = _.get(state, 'editor.id')
      if (!campaignId) {
        return null
      }
      const original = _.get(state.campaigns, campaignId)
      if (!original) {
        return null
      }
      return objectDiff(original, state.editor)
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges
      return diff && !_.isEmpty(diff)
    },
    templates({ campaigns }) {
      return _.values(campaigns).filter(c => c.state === 'template')
    },
    templatesByOrganisation: state => id => {
      //TODO: state -> users ???
      let campaigns = state.campaigns
      return _.values(campaigns).filter(
        c => c.state === 'template' && c.organisation === id
      )
    }
  },

  mutations: {
    async newTemplateToEditor(state, rootState) {
      state.editor = sureClone(DEFAULT_EDITOR)
      state.editor.organisation = rootState.organisation.id
      Vue.set(state.editor, 'isLoading', true)

      let defaultImpressionSettings = await ApiClient.get(
        `/default_impression_settings`
      )

      state.editor.settings = _.merge(
        {},
        DEFAULT_EDITOR.settings,
        defaultImpressionSettings
      )
      Vue.set(state.editor, 'isLoading', false)
    },
    addTemplate(state, template) {
      Vue.set(state.campaigns, template.id, template)
    },
    deleteTemplate(state, templateId) {
      Vue.delete(state.campaigns, templateId)
    }
  },

  actions: {
    async load({ commit }, organisationId) {
      debug('load', organisationId)
      let campaigns = await ApiClient.get(`/campaigns`, {
        params: {
          organisation_id: organisationId
        }
      })
      debug('load', campaigns)
      commit('set', ['campaigns', _.keyBy(campaigns, 'id')])
    },

    async createTemplate({ state, commit }) {
      let campaign = sureClone(state.editor)
      _.unset(campaign, 'id')
      debug('createTemplate', campaign)
      let response = await ApiClient.post('/campaigns', campaign)
      debug('createTemplate - response', response)
      commit('addTemplate', response)
      return response
    },

    async loadTemplateToEditor({ state, commit }, campaignId) {
      debug('loadTemplateToEditor', campaignId)
      const campaign = _.get(state.campaigns, campaignId)
      if (!campaign) {
        throw new Error('Template not found')
      }
      commit('set', ['editor', sureClone(campaign)])
    },

    async updateTemplateInEditor({ state, getters, commit }) {
      let campaignId = state.editor.id
      if (!getters.editorHasChanges) {
        throw new Error('No changes to save')
      }
      let changes = getters.editorChanges
      debug('updateTemplateInEditor', campaignId, changes)
      let result = await ApiClient.patch(`/campaigns/${campaignId}`, changes)
      debug('updateTemplateInEditor - result', result)
      commit('mapSet', [`campaigns`, campaignId, result])
      commit('set', ['editor', result])
    },
    async deleteTemplate({ commit }, campaignId) {
      debug('deleteTemplate', campaignId)
      let result = await ApiClient.delete(`/campaigns/${campaignId}`)
      debug('deleteTemplate - result', result)
      commit('deleteTemplate', campaignId)
    }
  }
})
