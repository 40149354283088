import _ from 'lodash'
import * as _debug from 'debug'
import ApiClient from '@/lib/ApiClient'

const debug = _debug('vuex:module:organisation')

import ModuleFactory from '../common/ModuleFactory'
import { ensureHttpsUrl } from '@/lib/DataUtils'

export default ModuleFactory({
  state: {
    organisation: {
      id: null,
      name: null,
      settings: null
    },
    channelAccess: []
  },
  getters: {
    id(state) {
      return state.organisation.id
    },
    channels(state) {
      debug('doing cahnnels now!')
      return _.orderBy(_.flatMap(state.channelAccess, 'channel'), 'name')
    }
  },
  mutations: {
    setOrganisation(state, organisation) {
      debug('mutation setOrganisation', organisation)
      state.organisation = organisation
    }
  },
  actions: {
    async load({ commit, dispatch }, organisationId) {
      debug('load', organisationId)
      let organisation = await ApiClient.get(`/organisations/${organisationId}`)
      debug('load', organisation)
      commit('setOrganisation', organisation)
      dispatch('loadChannels', organisationId)
    },

    async loadChannels({ commit }, organisationId) {
      debug('load channelAccess', organisationId)
      let channelAccess = await ApiClient.get(
        `/channel_access?organisation=${organisationId}`
      )
      channelAccess = _.map(channelAccess, function(cha) {
        cha.channel.logo_url = ensureHttpsUrl(cha.channel.logo_url)
        return cha
      })
      debug('load channelAccess', channelAccess)
      commit('set', ['channelAccess', channelAccess])
    }
  }
})
