import _ from 'lodash'
import axios from 'axios'
import * as _debug from 'debug'
import * as jwt_decode from 'jwt-decode'

const debug = _debug(`${process.env.VUE_APP_PREFIX}:ApiClient`)

const AUTH_TOKEN_KEY = process.env.VUE_APP_AUTH_TOKEN_KEY

const client = axios.create({
  baseURL: `/api/rest/`
})

export class ApiClient {
  static get authenticationHeaders() {
    const token = localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_KEY)
    if (!token) {
      return {}
    }
    return {
      Authorization: `Bearer ${token}`
    }
  }

  static requestConfig(config = {}) {
    config.headers = _.merge(
      _.get(config, 'headers', {}),
      ApiClient.authenticationHeaders
    )
    return config
  }

  static async get(uri, config = {}) {
    debug('GET request', uri)
    await ApiClient.refreshToken()
    const response = await client.get(uri, ApiClient.requestConfig(config))
    debug('GET response', uri, response)
    return _.get(response, 'data')
  }

  static async post(uri, payload = {}, config = {}) {
    debug('POST request', uri, ApiClient.sanitizePayload(payload))
    await ApiClient.refreshToken()
    const response = await client.post(
      uri,
      payload,
      ApiClient.requestConfig(config)
    )
    debug('POST response', uri, response)
    return _.get(response, 'data')
  }

  static async postMultipart(uri, data) {
    let config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    debug('POST MULTIPART request', uri, data)
    await ApiClient.refreshToken()
    const response = await client.post(
      uri,
      data,
      ApiClient.requestConfig(config)
    )
    debug('POST MULTIPART response', uri, response)
    return _.get(response, 'data')
  }

  static async patch(uri, payload = {}, config = {}) {
    debug('PATCH request', uri, ApiClient.sanitizePayload(payload))
    await ApiClient.refreshToken()
    const response = await client.patch(
      uri,
      payload,
      ApiClient.requestConfig(config)
    )
    debug('PATCH response', uri, response)
    return _.get(response, 'data')
  }

  static async delete(uri, config = {}) {
    debug('DELETE request', uri)
    await ApiClient.refreshToken()
    const response = await client.delete(uri, ApiClient.requestConfig(config))
    debug('DELETE response', uri, response)
    return _.get(response, 'data')
  }

  static async refreshToken() {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    if (!token) {
      return false
    }
    const payload = jwt_decode(token)
    const exp = _.get(payload, 'exp')
    if (!exp || exp - Math.round(Date.now() / 1000) > 60) {
      return false
    }
    try {
      debug('refresh token', token)
      const response = await client.post('/auth/refresh', {
        token: token
      })
      let newToken = _.get(response, 'data.token')
      if (!newToken) {
        throw new Error('response does not contain token')
      }
      debug('new token', newToken)
      localStorage.setItem(AUTH_TOKEN_KEY, newToken)
      return true
    } catch (err) {
      debug('failed to refresh token')
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }

  static sanitizePayload(payload = {}) {
    let password = _.get(payload, 'password')
    if (password) {
      payload = JSON.parse(JSON.stringify(payload))
      _.set(payload, 'password', '***')
    }
    return payload
  }
}

export default ApiClient
