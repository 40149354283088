<template>
  <div class="app-toast">
    <v-snackbar
      v-if="show"
      v-model="show"
      color="primary"
      :bottom="false"
      :top="true"
      :timeout="0"
    >
      New update for this application is available.
      <v-btn v-if="close" dark text @click="refreshClicked">
        Reload
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
// import _ from 'lodash'

export default {
  name: 'Toast',

  data() {
    return {
      show: null,
      close: true,
      registration: null
    }
  },
  created() {
    // Listen for swUpdated event from service worker and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showToast, { once: true })
  },

  methods: {
    showToast(detail) {
      let me = this
      me.registration = detail
      me.show = true
    },
    refreshClicked() {
      let me = this
      me.show = false
      // Protect against missing registration.waiting.
      if (!me.registration || !me.registration.waiting) {
        me.$debug(
          'no service worker registration found or it is not in waiting state'
        )
        return
      }
      me.$debug('post skipWaiting for service worker registration')
      me.registration.waiting.postMessage('skipWaiting')
    }
  }
}
</script>
