import _ from 'lodash'
//import Vue from 'vue'
import * as _debug from 'debug'
import ApiClient from '@/lib/ApiClient'
const debug = _debug('vuex:module:admin:channels')
import ModuleFactory from '@/store/common/ModuleFactory'
import { ensureHttpsUrl } from '@/lib/DataUtils'

export default ModuleFactory({
  state: {
    channels: null
  },
  getters: {
    channels({ channels }) {
      return _.values(channels)
    }
  },
  mutations: {
    setChannels(state, channels) {
      state.channels = channels
    }
  },
  actions: {
    async load({ commit }) {
      debug('load')
      let channels = await ApiClient.get(`/channels`)
      channels = _.map(channels, function(ch) {
        ch.logo_url = ensureHttpsUrl(ch.logo_url)
        return ch
      })
      debug('load', channels)
      commit('setChannels', channels)
    }
  }
})
